import restaurant from "./images/img9.jpeg";
import img_card from "./images/img11.jpeg";
import imgOmSuflet from "./images/headerrestaurant.jpg";
import imgheader from "./images/header.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*bruma9595@gmail.com*/}

const data = {
    introData: [{
        mire: "Marin",
        mireasa: "Carolina",
        data: "30 Iulie 2022",
        data_confirmare: "20 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "bruma9595@gmail.com", 
       tel: "+373 695 17 198",
       phone: "tel:+37369517198",
       viber: "viber://chat?number=%2B37369517198",
       whatsapp: "https://wa.me/+37369517198",
       messenger: "https://www.messenger.com/t/bruma.marin.12",
       tel1: "+373 787 78 418",
       phone1: "tel:+37378778418",
       viber1: "viber://chat?number=%2B37378778418",
       whatsapp1: "https://wa.me/+37378778418",
       messenger1: "https://www.messenger.com/t/carolina.talmazan.1",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "The Crown",
            data: "30 iulie 2022, sâmbătă, ora 17:00",
            adress: "str. Boris Glavan 17, or. Cantemir",
            harta: "https://goo.gl/maps/SXDQAKR22eFUg5kE7",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2757.773254257133!2d28.19474471558482!3d46.27461027911893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b605468d65cacd%3A0xd20ae7cc3333750e!2sStrada%20Boris%20Gl%C4%83van%2017%2C%20Cantemir!5e0!3m2!1sro!2s!4v1650106581823!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Brumă",
            parintii_nume2: "Leonid & Tatiana",
            parintii_nume3: "Talmazan",
            parintii_nume4: "Serafim & Svetlana",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Petreanu Gheorghe & Drăgălina",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;